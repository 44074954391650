@import "node_modules/govuk-frontend/govuk/all";
@import "loader";
@import "header";

.govuk-button {
  background-color: #265A87;
  border-radius: 4px;
  &:hover {
      background-color:  #204d74
  }
}

.govuk-button--secondary {
  background-color: #f3f2f1;
  border-radius: 4px;
  &:hover {
      background-color:  #b1b4b6
  }
}
