.nsdc-header{
  background-color: #fff;
}

.header-link:hover{
  border-bottom: 3px solid black;
}

.border-banner {
  background-image: url(../public/assets/images/banner.svg);
  background-size: contain;
  height: 10px;
  width: 100%;

  @media (max-width: 40.0625em){
    background-repeat: no-repeat;
  }
}

@media (min-width: 40.0625em){
  .govuk-header__link--homepage:focus {
    box-shadow: 0px -30px #fd0;
  }
}

.govuk-header__container {
  border-bottom: 0;
  margin-bottom: 10px;
}